/* importのためクラス名変更不可 */
/* 三本線エリア */
.bm-burger-button { 
  position: fixed;
  width: 28px;
  height: 28px;
  right: 20px;
  top: 15px;
}

/* 三本線の線 */
.bm-burger-bars {
  border-radius: 10px;
  background: var(--purple);
}

/* 三本線の線*/
.bm-burger-bars-hover {
  box-shadow: -0.5px -0.5px var(--white), 0.5px -0.5px var(--white),
  0.5px 0.5px var(--white), -0.5px 0.5px var(--white),
  0 0 0.1em var(--white), 0 0 0.1em var(--white) inset,
  0 0 0.5em var(--white), 0 0 0.5em var(--white) inset,
  0 0 1em var(--white), 0 0 1em var(--white);
  background-color:var(--purple); 
}

/* 閉じるボタンエリア */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* 閉じるボタンの色 */
.bm-cross {
  background: var(--white);
}

/*バーガーメニューエリア全体*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  top: 0;
}

/* メニューの部分 */
.bm-menu {
  background: var(--black);
  /* font-size: 1.15em; */
}

/* メニューリストの部分 */
.bm-item-list {
  top: 60px;
  position: absolute;
  width: 100%;
}

/* メニューの個々の設定 */
.bm-item-list a { 
  border-bottom:1px solid var(--glay);
  width: 100%;
  height: 60px;
  line-height: 50px;
  padding-left: 15px;
  text-decoration: none;
  color: var(--white);
}

.bm-item-list a:hover {
  box-shadow:
  0 0 5px var(--white),
  0 0 5px var(--white),
  0 0 5px var(--white);
  background-color: var(--glay);
}

/* 背景 */
.bm-overlay {
  background: rgba(70, 85, 100, 0.7)!important;
  top: 0;
}
