.download_area {
    justify-content: space-around;
    margin-top: 20px;
}
.download_area ul {
    padding-left: 10px;
    list-style-type: square;
    color: var(--white);
}
.download_area a {
    text-decoration: none;
    position: relative;
	padding: 0.1em 0.3em;
	display: inline-block;
	transition: .3s;
	color: var(--white);
    font-size: 14px;
}
.download_area a::after {
	position: absolute;
	bottom: 0;
	left: 50%;
	content: '';
	width: 0;
	height: 1px;
	transition: .3s;
	transform: translateX(-50%);
    border-radius: 0.5px; /* 角をより細くする */
    box-shadow: -0.5px -0.5px var(--pink), 0.5px -0.5px var(--pink),
        0.5px 0.5px var(--pink), -0.5px 0.5px var(--pink),
        0 0 0.1em var(--pink), 0 0 0.1em var(--pink) inset,
        0 0 0.5em var(--pink), 0 0 0.5em var(--pink) inset,
        0 0 1em var(--pink), 0 0 1em var(--pink);
}
.download_area a:hover::after {
	width: 100%;
}
.download_area a:active {
    color: var(--pink)!important; /* クリック時の色を指定 */
}
.nomal_area {
    border: 2px dashed var(--white);
    border-radius: 5px;
    padding: 15px;
    position: relative;
    display: flex;
    justify-content: center;
}
.nomal_area::before {
    color: var(--lightglay);
    background-color: var(--black);
    content: "Nomal";
    left: 50px;
    padding: 3px 3px;
    text-align: center;
    position: absolute;
    top: -25px;
    transform: translateX(-50%);
    font-size: 28.8px;
}
.summary_area {
    border: 2px dashed var(--white);
    border-radius: 5px;
    padding: 15px;
    position: relative;
    display: flex;
    justify-content: center;
}
.summary_area::before {
    color: var(--lightglay);
    background-color: var(--black);
    content: "Summary";
    left: 50px;
    padding: 3px 3px;
    text-align: center;
    position: absolute;
    top: -25px;
    transform: translateX(-50%);
    font-size: 28.8px;
}
/*両方なし*/
.both_none {
    justify-content: space-around;
    width: 100%;
}
@media screen and (min-width:768px) {
    .download_area {
        display: flex;
    }
    .download_area ul {
        padding-left: 40px;
    }
    .download_area a {
        font-size: 16px;
    }
    .nomal_area {
        padding: 30px;
    }
    .summary_area {
        padding: 30px;
    }
    /*両方なし*/
    .both_none {
        display: flex;
    }
}
/* 水平方向のオフセット：影の左右の方向への位置調整。負の値は左に、正の値は右に影を移動させます。
垂直方向のオフセット：影の上下の方向への位置調整。負の値は上に、正の値は下に影を移動させます。
ぼかし距離：影のぼかしの範囲を指定します。これにより、影がどれだけぼかされるかが決まります。
スプレッド半径：影の拡散を制御します。正の値は影を広げ、負の値は影を縮小します。
色：影の色を指定します。
inset：省略可能なキーワード。指定されると、影は要素の内側に描画されます。
提供された box-shadow プロパティでは、次のように影が設定されています：

4つの角に対する斜めの影（左上、右上、右下、左下）
外側のぼかし距離が0.1emの影
内側のぼかし距離が0.5emの影
外側のぼかし距離が1emの影
これにより、要素の周囲に異なる幅の影が付きます。 */
