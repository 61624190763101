/* 変数 */
:root {
    --black: #2e2930;
    --white: #eaedf7;
    --purple: #8d93c8;
    --blue: #87cefa;
    --pink: #e198b4;
    --orange: #ee836f;
    --yellow: #ffd900;
    --glay: #9790a4;
    --lightglay: #dcdcdc;
    --darkblue: #3e62ad;
}
html {
    background-color: var(--black);
}
body {
    padding-top: 60px;
    font-family: 'MisakiGothic2nd';
}
p {
    color: var(--white);
}
main {
    margin: 0 20px;
}
/* @font-face {
    font-family: 'MisakiGothic';
    src: url('../../public/fonts/misaki_gothic.ttf') format('truetype');
} */
@font-face {
    font-family: 'MisakiGothic2nd';
    src: url('../../public/fonts/misaki_gothic_2nd.ttf') format('truetype');
}

/* loginform */
/* inactive tabs */
.amplify-tabs__item {
    color: var(--glay);
}
/* active tabs */
.amplify-tabs__item--active {
    color: var(--darkblue);
    border-color: var(--darkblue);
}
/* input area */
.amplify-label {
    color: var(--black);
}
.amplify-input {
    color: var(--black);
    border-color: var(--darkblue);
}
.amplify-input:focus {
    outline: none;
    border: 1px solid var(--darkblue);
    box-shadow: -1px -1px var(--blue), 1px -1px var(--blue),
                1px 1px var(--blue), -1px 1px var(--blue),
                0 0 0.1em var(--blue), 0 0 0.1em var(--blue) inset,
                0 0 0.5em var(--blue), 0 0 0.5em var(--blue) inset,
                0 0 1em var(--blue), 0 0 1em var(--blue);
}
.amplify-input::placeholder {
    color: var(--glay);
}
/* password eye */
.amplify-field__show-password {
    border-color: var(--darkblue);
}
.amplify-field__show-password:focus {
    outline: none;
    border: 1px solid var(--darkblue);
    box-shadow: -1px -1px var(--blue), 1px -1px var(--blue),
                1px 1px var(--blue), -1px 1px var(--blue),
                0 0 0.1em var(--blue), 0 0 0.1em var(--blue) inset,
                0 0 0.5em var(--blue), 0 0 0.5em var(--blue) inset,
                0 0 1em var(--blue), 0 0 1em var(--blue);
}
/* button */
.amplify-button--primary {
    background-color: var(--darkblue);
}
.amplify-button--primary:focus {
    outline: none;
    border: 1px solid var(--darkblue);
    box-shadow: -1px -1px var(--blue), 1px -1px var(--blue),
                1px 1px var(--blue), -1px 1px var(--blue),
                0 0 0.1em var(--blue), 0 0 0.1em var(--blue) inset,
                0 0 0.5em var(--blue), 0 0 0.5em var(--blue) inset,
                0 0 1em var(--blue), 0 0 1em var(--blue);
}
/* password forgot */
.amplify-button--small {
    color: var(--darkblue);
}
.amplify-button--small:focus {
    color: var(--darkblue);
    outline: none;
    border: 1px solid var(--darkblue);
    box-shadow: -1px -1px var(--blue), 1px -1px var(--blue),
                1px 1px var(--blue), -1px 1px var(--blue),
                0 0 0.1em var(--blue), 0 0 0.1em var(--blue) inset,
                0 0 0.5em var(--blue), 0 0 0.5em var(--blue) inset,
                0 0 1em var(--blue), 0 0 1em var(--blue);
}