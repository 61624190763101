/* チャットエリアの表示制限 */
.chat_area {
    height: 71vh;
    overflow-y: auto;
    padding: 0px 25px;
    width: 100%;
}
/* bot */
/* メッセージエリア */
.bot_message {
    margin: 5px 0;
    text-align: left;
}
/* チャット部分 */
.bot_message p {
    display: inline-block;
    position: relative; 
    margin: 0;
    padding: 10px;
    border-radius: 10px;
    background: var(--lightglay);
    font-size: 16px;
    font-family: serif;
    max-width: 180px;
    color: var(--black);
}
/* 吹き出しの部分 */
.bot_message p:after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 3px; 
    left: -19px;
    border: 8px solid transparent;
    border-right: 18px solid var(--lightglay);
    transform: rotate(35deg);
    z-index: -1;
}
/* user */
/* メッセージエリア */
.user_message {
    margin: 5px 0;
    text-align: right;
}
/* チャット部分 */
.user_message p {
    display: inline-block;
    position: relative; 
    margin: 0;
    padding: 10px;
    max-width: 180px;
    border-radius: 10px;
    color: var(--white);
    background: var(--darkblue);
    font-size: 16px;
    font-family: serif;
}
/* 吹き出しの部分 */
.user_message p:after {
    content: "";
    position: absolute;
    display: inline-block;
    top: 3px; 
    right: -19px;
    border: 8px solid transparent;
    border-left: 18px solid var(--darkblue);
    transform: rotate(-35deg);
    z-index: -1;
}
/* スクロールバー */
.chat_area::-webkit-scrollbar {
    background: var(--lightglay);
    width: 5px;
    height: 5px;
    border-radius: 10px;
}
.chat_area::-webkit-scrollbar-thumb {
    background-color: var(--glay);
    border-radius: 10px;
}
@media screen and (min-width:768px) {
    /* チャットエリアの表示制限 */
    /* bot */
    /* チャット部分 */
    .bot_message p {
        max-width: 350px;
    }

    /* user */
    /* チャット部分 */
    .user_message p {
        max-width: 350px;
    } 
}

@media screen and (min-width:1024px) {
    /* チャットエリアの表示制限 */
    /* bot */
    /* チャット部分 */
    .bot_message p {
        max-width: 500px;
    }
    /* user */
    /* チャット部分 */
    .user_message p {
        max-width: 500px;
    }
}
