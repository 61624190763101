/* 320px~ */
/* header area */
.header_area {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    position:fixed;
    top: 0;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
    background-color: var(--black);
    z-index: 3;
}
/* logo area */
.header_logo_area {
    width: 30px;
    margin: 0 0 0 20px;
}
/* logo */
.header_logo {
    width: 40px;
    height: 40px;
    color: var(--purple);
}
/* title */
.header_title {
    margin: 10px;
    color: var(--purple);
    letter-spacing: 10px;
    font-size: 22px;
}
/* logout button */
.logout_button {
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
}
.logout_icon {
    width: 30px;
    height: 30px;
    position: fixed;
    right: 60px;
    top: 15px;
    color: var(--purple);
}
.logout_icon:hover {
    filter: drop-shadow(0 0 1px var(--white)) drop-shadow(0 0 1px var(--white)) drop-shadow(0 0 1px var(--white)) ; 
}

@media screen and (min-width:768px) {
    /* logo area */
    .header_logo_area {
        width: 60px;
        height: 100%;
    }
    /* logo */
    .header_logo {
        width: 60px;
        height: 60px;
    }
    /* title */
    .header_title {
        margin: 5px;
    }
}