/* パンくずエリア */
.breadcrumb_nav {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 10px 0px 10px 0px;
    height: 30px;
    font-size: 18px;
}
/* ＞ */
.breadcrumb_item::after {
    content: '>';
    margin: 0px 10px;
}
/* 最後のリンクの後ろに>を追加しないようにする */
.breadcrumb_item:last-child::after {
    content: none;
}
/* リンク */
.breadcrumb_item a {
    text-decoration: none;
    color: var(--white);
}
.breadcrumb_item a:hover {
    text-decoration: underline solid var(--glay);
}
/* 現在位置 */
.breadcrumb_item.active {
    color: var(--purple); /* アクティブなリンクの色 */
}