/* 320px~ */
/* radio */
.radio_area {
    height: auto;
    text-align: center;
}
.radio_area_p {
    display: flex;
    justify-content: center;
}
.radio_input_area {
    display: flex;
    justify-content: center;
}
.radio_input_area input {
	display: none;
}
.radio_area label {
    width: 90px;
    height: 50px;
	display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
	cursor: pointer;
    color: var(--white);
	font-size: 14px;
	line-height: 1;
    border-radius: 5px;
	transition: .3s;
    box-shadow: -1px -1px rgba(192, 192, 192, 0.5), 
                1px -1px rgba(192, 192, 192, 0.5),
                1px 1px rgba(192, 192, 192, 0.5),
                -1px 1px rgba(192, 192, 192, 0.5);
}
.radio_area input[type="radio"]:checked + label {
    color: var(--white);
    box-shadow: -1px -1px rgb(129, 250, 133), 1px -1px rgb(129, 250, 133),
                1px 1px rgb(129, 250, 133), -1px 1px rgb(129, 250, 133),
                0 0 0.1em rgb(129, 250, 133), 0 0 0.1em rgb(129, 250, 133) inset,
                0 0 0.5em rgb(129, 250, 133), 0 0 0.5em rgb(129, 250, 133) inset,
                0 0 1em rgb(129, 250, 133), 0 0 1em rgb(129, 250, 133);
}

/* チャット表示エリア */
.chat_container {
    margin-top: 20px;
}
/* 送信エリア */
/* 入力部分 */
.chat_input_area {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
}
.chat_input_text {
    margin: 0;
    box-sizing: border-box;
    width: calc(100% - 40px);
    height: 60px;
    resize: vertical;
    overflow-y: auto;
    padding: 5px;
    border: 1px solid var(--blue);
    color: var(--white);
    background-color: var(--black);
    resize: none;
}
.chat_input_text:focus {
    outline: none;
    border: 5px solid var(--blue);
    box-shadow: -1px -1px var(--blue), 1px -1px var(--blue),
                1px 1px var(--blue), -1px 1px var(--blue),
                0 0 0.1em var(--blue), 0 0 0.1em var(--blue) inset,
                0 0 0.5em var(--blue), 0 0 0.5em var(--blue) inset,
                0 0 1em var(--blue), 0 0 1em var(--blue);
}
.chat_input_text::placeholder {
    color: var(--white);
}
/* スクロールバー */
.chat_input_text::-webkit-scrollbar {
    background: var(--lightglay);
    width: 5px;
    height: 5px;
    border-radius: 10px;
}
.chat_input_text::-webkit-scrollbar-thumb {
    background-color: var(--glay);
    border-radius: 10px;
}
/* 送信ボタン */
.chat_submit_button {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 40px;
	margin: auto;
    padding: 0;
	border: 1px solid var(--blue);
	color:  var(--blue);
	transition: 0.5s;
    background-color: var(--black);
}
.chat_submit_button:hover {
	color: var(--blue);
    cursor: pointer;
    box-shadow: -1px -1px var(--blue), 1px -1px var(--blue),
                1px 1px var(--blue), -1px 1px var(--blue),
                0 0 0.1em var(--blue), 0 0 0.1em var(--blue) inset,
                0 0 0.5em var(--blue), 0 0 0.5em var(--blue) inset,
                0 0 1em var(--blue), 0 0 1em var(--blue);
}
.chat_submit_button:disabled {
    color: var(--white);
    background-color: rgba(70, 85, 100, 0.7);
    box-shadow: none;
    cursor: not-allowed;
}

@media screen and (min-width:768px) {
    /* button&chat_text */
    .radio_chat_area {
        display: flex;
    }
    /* radio */
    .radio_area {
        width: 150px;
        height: auto;
        text-align: left;
    }
    .radio_area_p {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        margin: 16px 0;
    }
    .radio_area_p p {
        margin: 0;
    }
    .radio_input_area {
        flex-direction: column;
    }
    .radio_input_area input {
        display: none;
    }
    .radio_area label {
        height: 50px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin: 5px 0;
        /* background: var(--lightglay); */
        /* color: #555e64; */
        color: var(--white);
        font-size: 14px;
        text-align: center;
        line-height: 1;
        border-radius: 5px;
        transition: .3s;
        box-shadow: -1px -1px rgba(192, 192, 192, 0.5), 
                1px -1px rgba(192, 192, 192, 0.5),
                1px 1px rgba(192, 192, 192, 0.5),
                -1px 1px rgba(192, 192, 192, 0.5);
    }
    .radio_area input[type="radio"]:checked + label {
        /* background-color: var(--green);
        color: var(--white); */
        color: var(--white);
        border-radius: 5px;
        box-shadow: -1px -1px rgb(129, 250, 133), 1px -1px rgb(129, 250, 133),
            1px 1px rgb(129, 250, 133), -1px 1px rgb(129, 250, 133),
            0 0 0.1em rgb(129, 250, 133), 0 0 0.1em rgb(129, 250, 133) inset,
            0 0 0.5em rgb(129, 250, 133), 0 0 0.5em rgb(129, 250, 133) inset,
            0 0 1em rgb(129, 250, 133), 0 0 1em rgb(129, 250, 133);
        padding: 10px;
    }

    /* チャット表示エリア */
    .chat_container {
        width: calc(100% - 150px);;
        justify-content: end;
        display: flex;
    }
    /* 送信エリア */
    .form_area_all {
        margin: 10px auto 0px 185px;
    }
    .chat_form_area {
        text-align: center;
        height: 60px;
        text-wrap: nowrap;
    }
    /* 送信ボタン */
    .chat_submit_button {
        height: 60px;
    }
}

@media screen and (min-width:1024px) {
    /* チャット表示エリア */
    .chat_container {
        max-width: 1600px;
    }
    /* 送信エリア */
    .form_area_all {
        max-width: 1600px;
    }
    .chat_form_area {
        max-width: 1600px;
    }
}
