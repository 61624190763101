/* 320px~ */
/* packman */
.user_name_area {
  margin: 10px 0 0 10px;
  width: 60px;
  height: 60px;
  position: relative;
}
.pack_man {
  position: absolute;
  width: 30px;
  height: 60px;
  top: 0;
  left: 0;
  border-radius: 30px 0 0 30px;
  background: var(--yellow);
}
.pack_man::before,
.pack_man::after {
  content: "";
  display: block;
  width: 60px;
  height: 30px;
  position: absolute;
  background: var(--yellow);
  left: 0;
}
.pack_man::before {
  top: 30px;
  border-radius: 0 0 30px 30px;
  transform-origin: center top;
  transform: rotate(40deg);
  animation: opening_under cubic-bezier(0.55, 0.06, 0.68, 0.19) .5s 0s infinite alternate;
}
@keyframes opening_under {
  60%,
  100% {
    transform: rotate(-5deg);
  }
}
.pack_man::after {
  top: 0;
  border-radius: 30px 30px 0 0;
  transform-origin: center bottom;
  transform: rotate(-40deg);
  animation: opening_upper cubic-bezier(0.55, 0.06, 0.68, 0.19) .5s 0s infinite alternate;
}
@keyframes opening_upper {
  60%,
  100% {
    transform: rotate(5deg);
  }
}

/* user mname */
.user_name {
  position: relative;
  left: 60px;
  top: 20px;
  text-wrap: nowrap;
  font-size: 16px;
  color: var(--white);
  letter-spacing: 5px;
}

/* icon area */
.link_area {
  text-align: center;
  margin: 15px auto;
}

/* icon */
.link_icon_inky {
  width: 200px;
  height: 200px;
  fill: var(--blue);
}
.link_icon_inky:hover {
  filter: drop-shadow(0 0 1px var(--white)) drop-shadow(0 0 3px var(--white)) drop-shadow(0 0 5px var(--white)); 
}
.link_icon_clyde {
  width: 200px;
  height: 200px;
  fill: var(--orange);
}
.link_icon_clyde:hover {
  filter: drop-shadow(0 0 1px var(--white)) drop-shadow(0 0 3px var(--white)) drop-shadow(0 0 5px var(--white)); 
}
.link_icon_pinky {
  width: 200px;
  height: 200px;
  fill: var(--pink);
}
.link_icon_pinky:hover {
  filter: drop-shadow(0 0 1px var(--white)) drop-shadow(0 0 3px var(--white)) drop-shadow(0 0 5px var(--white)); 
}

/* finger_icon */
.finger_icon_area {
  color: var(--white);
  cursor: text;
  margin: 0;
}

@media screen and (min-width: 768px) {
  /* pacman */
  .user_name_area {
    width: 100px;
    height: 100px;
  }
  .pack_man {
    width: 50px;
    height: 100px;
    border-radius: 50px 0 0 50px;
  }
  .pack_man::before,
  .pack_man::after {
    width: 100px;
    height: 50px;
  }
  .pack_man::before {
    top: 50px;
    border-radius: 0 0 50px 50px;
  }
  .pack_man::after {
    border-radius: 50px 50px 0 0;
  }

  /* user mname */
  .user_name {
    left: 100px;
    top: 32px;
    font-size: 26px;
  }

  /* icon area */
  .link_area {
    display: flex;
    justify-content: space-around;
  }
  
  /* finger_icon */
  .finger_icon_area {
    font-size: 14px
  }
}

@media screen and (min-width: 1025px) {
  /* user mname */
  .user_name {
    font-size: 32px;
    letter-spacing: 10px;
  }

  /* icon */
  .link_icon_inky {
    width: 300px;
    height: 300px;
  }
  .link_icon_clyde {
    width: 300px;
    height: 300px;
  }
  .link_icon_pinky {
    width: 300px;
    height: 300px;
  }
}