/* title area*/
.download_title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}
.download_title h1 {
    margin: 0;
    font-size: 18px;
    text-wrap: nowrap;
    color: var(--white);
}
.ghost_blinky {
    width: 50px;
    height: 50px;
    fill: var(--pink);
}
.attention_area {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}
.attention_area p {
    max-width: 260px;
    margin: 0;
    color: var(--lightglay);
}

@media screen and (min-width:768px) {
    /* title area*/
    .download_title h1 {
        font-size: 32px;
    }
    .attention_area p {
        max-width: 1600px;
        margin: 0;
        color: var(--lightglay);
    }
}
