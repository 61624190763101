.drop_area {
    height: 350px;
    border: 2px dashed var(--lightglay);
    text-align: center;
    cursor: pointer;
    color: var(--white);
    font-size: 17px;
    align-items: center;
    display: flex;
    justify-content: center
}
.drop_area svg {
    height: 50px;
    width: 50px;
}
.sending_button {
    cursor: pointer;
    margin-top: 30px;
    width: 160px;
    height: 60px;
    border-radius: 70px;
    color: var(--white);
    background-color: var(--black);
    border: 1px solid var(--lightglay);
    float: right;
    margin: 30px 0;
}
.sending_button:hover {
    cursor: pointer;
    box-shadow: -1px -1px var(--lightglay), 1px -1px var(--lightglay),
        1px 1px var(--lightglay), -1px 1px var(--lightglay),
        0 0 0.1em var(--lightglay), 0 0 0.1em var(--lightglay) inset,
        0 0 0.5em var(--lightglay), 0 0 0.5em var(--lightglay) inset,
        0 0 1em var(--lightglay), 0 0 1em var(--lightglay);
}
.sending_button:disabled {
    cursor: not-allowed;
    background-color: rgba(70, 85, 100, 0.7);
    box-shadow: none;
    color: var(--black);
}
.canceling_button {
    cursor: pointer;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    background-color: var(--lightglay);
    border: none;
    margin-right: 10px;
}
#fileInput {
    display: none;
}
@media screen and (min-width:768px) {
    .drop_area {
        height: 200px;
        color: var(--white);
        font-size: 30px;
    }
    .drop_area_p {
        margin: 0;
    }
    .sending_button {
        cursor: pointer;
        margin-top: 30px;
        width: 300px;
        height: 75px;
        border-radius: 70px;
        color: var(--white);
        background-color: var(--black);
        border: 1px solid var(--lightglay);
        float: right;
        margin: 30px 20px 0 0;
    }
    .sending_button {
        cursor: pointer;
        margin-top: 30px;
        width: 280px;
        height: 75px;
        border-radius: 70px;
        color: var(--white);
        background-color: var(--black);
        border: 1px solid var(--lightglay);
        float: right;
        margin: 30px 0;
    }
    .sending_button:hover {
        cursor: pointer;
        box-shadow: -1px -1px var(--lightglay), 1px -1px var(--lightglay),
            1px 1px var(--lightglay), -1px 1px var(--lightglay),
            0 0 0.1em var(--lightglay), 0 0 0.1em var(--lightglay) inset,
            0 0 0.5em var(--lightglay), 0 0 0.5em var(--lightglay) inset,
            0 0 1em var(--lightglay), 0 0 1em var(--lightglay);
    }
    .sending_button:disabled {
        cursor: not-allowed;
        background-color: rgba(70, 85, 100, 0.7);
        box-shadow: none;
        color: var(--black);
    }
    .canceling_button {
        cursor: pointer;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        background-color: var(--lightglay);
        border: none;
        margin-right: 10px;
    }
    #fileInput {
        display: none;
    }
}
